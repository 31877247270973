//
// DISCLAIMER
//
// Copyright 2019-2022 ArangoDB GmbH, Cologne, Germany
//

import { numberFormat } from "humanize";
import _ from "lodash";
import React from "react";
import ReactJson from "react-json-view";
import { RouteComponentProps } from "react-router-dom";
import { Header, Popup, Table } from "semantic-ui-react";
import { Node as ApiNode, NodeList as ApiNodeList } from "../../api/data/v1/idata";
import { DateTimePopupWithUTCAndLocalTime } from "../../util/dateAndTimeUtils/DateTime";
import { IWithRefreshProps } from "../../util/WithRefresh";

export interface INodesViewArgs extends IWithRefreshProps, RouteComponentProps {
  nodes?: ApiNodeList;
}

export const NodesView = ({ ...args }: INodesViewArgs) => {
  const nodes = args.nodes || {};
  const count = _.size(nodes.items);
  return (
    <div>
      <Header sub>K8s Nodes ({count})</Header>
      <NodesTableView {...args} />
    </div>
  );
};

const NodesTableView = ({ ...args }: INodesViewArgs) => {
  let list = (args.nodes || {}).items || [];
  if (list.length == 0) {
    return <span>None</span>;
  }
  const sortedList = _.orderBy(list, "name");
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Zone</Table.HeaderCell>
          <Table.HeaderCell>Node pool</Table.HeaderCell>
          <Table.HeaderCell>CPU</Table.HeaderCell>
          <Table.HeaderCell>Memory</Table.HeaderCell>
          <Table.HeaderCell>Volumes</Table.HeaderCell>
          <Table.HeaderCell>Pods</Table.HeaderCell>
          <Table.HeaderCell>Scheduling V2</Table.HeaderCell>
          <Table.HeaderCell>Kubelet Version</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedList.map((x) => (
          <NodeStatusView {...args} key={x.name} node={x} />
        ))}
      </Table.Body>
      <NodesSummaryView nodes={sortedList} />
    </Table>
  );
};

interface INodeStatusViewArgs extends IWithRefreshProps, RouteComponentProps {
  node?: ApiNode;
}

const NodeStatusView = ({ ...args }: INodeStatusViewArgs) => {
  const node = args.node || {};
  const info = node.info || {};
  const allocatable = node.allocatable || {};
  const allocated = node.allocated || {};
  const usage = node.usage || {};
  return (
    <Table.Row>
      <Table.Cell>{node.name}</Table.Cell>
      <Table.Cell>{node.status}</Table.Cell>
      <Table.Cell>{info.instanceType}</Table.Cell>
      <Table.Cell>{info.zone}</Table.Cell>
      <Table.Cell>{info.groupName}</Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>{numberFormat((usage.cpu || 0) * 100, 1)}%</i>
            </span>
          }
          content={
            <span>
              {allocated.cpu} of {allocatable.cpu}
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>{numberFormat((usage.memory || 0) * 100, 1)}%</i>
            </span>
          }
          content={
            <span>
              {allocated.memory} of {allocatable.memory}
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>{numberFormat((usage.attachableVolumes || 0) * 100, 1)}%</i>
            </span>
          }
          content={
            <span>
              {allocated.attachableVolumes} of {allocatable.attachableVolumes}
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <span>
              <i>{numberFormat((usage.pods || 0) * 100, 1)}%</i>
            </span>
          }
          content={
            <span>
              {allocated.pods} of {allocatable.pods}
            </span>
          }
        />
      </Table.Cell>
      <Table.Cell>{node.scheduling_v2 ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>
        <Popup wide="very" position="bottom right" trigger={<u>{info.kubeletVersion}</u>} content={<ReactJson src={node} collapsed={1} />} on="click" pinned />
      </Table.Cell>
      <Table.Cell>{node ? <DateTimePopupWithUTCAndLocalTime dateTime={node.created_at} label="Created at" /> || "" : ""}</Table.Cell>
    </Table.Row>
  );
};

interface INodesSummaryViewArgs {
  nodes: ApiNode[];
}

const NodesSummaryView = ({ ...args }: INodesSummaryViewArgs) => {
  const l = args.nodes.length;
  if (l == 0) {
    return <Table.Footer />;
  }
  const average = (getter: (n: ApiNode) => number) => {
    return (
      _.reduce(
        args.nodes.map((x) => getter(x)),
        (s, n) => s + n,
        0
      ) / l
    );
  };
  const averageUsageCPU = average((x) => (x.usage || {}).cpu || 0);
  const averageUsageMemory = average((x) => (x.usage || {}).memory || 0);
  const averageUsageVolumes = average((x) => (x.usage || {}).attachableVolumes || 0);
  const averageUsagePods = average((x) => (x.usage || {}).pods || 0);

  const min = (getter: (n: ApiNode) => number) => {
    return _.min(args.nodes.map((x) => getter(x))) || 0;
  };
  const minUsageCPU = min((x) => (x.usage || {}).cpu || 0);
  const minUsageMemory = min((x) => (x.usage || {}).memory || 0);
  const minUsageVolumes = min((x) => (x.usage || {}).attachableVolumes || 0);
  const minUsagePods = min((x) => (x.usage || {}).pods || 0);

  const max = (getter: (n: ApiNode) => number) => {
    return _.max(args.nodes.map((x) => getter(x))) || 0;
  };
  const maxUsageCPU = max((x) => (x.usage || {}).cpu || 0);
  const maxUsageMemory = max((x) => (x.usage || {}).memory || 0);
  const maxUsageVolumes = max((x) => (x.usage || {}).attachableVolumes || 0);
  const maxUsagePods = max((x) => (x.usage || {}).pods || 0);

  return (
    <Table.Footer>
      <Table.Row>
        <Table.Cell>Average</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{numberFormat(averageUsageCPU * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(averageUsageMemory * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(averageUsageVolumes * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(averageUsagePods * 100, 1)}%</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Minimum</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{numberFormat(minUsageCPU * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(minUsageMemory * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(minUsageVolumes * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(minUsagePods * 100, 1)}%</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Maximum</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{numberFormat(maxUsageCPU * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(maxUsageMemory * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(maxUsageVolumes * 100, 1)}%</Table.Cell>
        <Table.Cell>{numberFormat(maxUsagePods * 100, 1)}%</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Row>
    </Table.Footer>
  );
};
