//
// DISCLAIMER
//
// Copyright 2022-2023 ArangoDB GmbH, Cologne, Germany
//

import styled from "@emotion/styled";
import moment from "moment";
import React from "react";
import { Popup } from "semantic-ui-react";
import { usePersistentStorage } from "../storage/GobalStore";
import { DATE_TYPE_PREFERENCE } from "../storage/types";
import { IDateTimeArgs, HumanisedDurationArgs, ElapsedTimeArgs, IFormattedDateWithLabelArgs, IDateTimePopupWithUTCAndLocalTimeArgs } from "./types";

const DATE_TIME_FORMAT = "MMMM Do YYYY, h:mm:ss A z";
export const HUMANIZED_DATE_FORMAT = "MMMM Do YYYY, h:mm A z";

export enum DateTimeTransformationTypes {
  UTC = "utc",
  LOCAL = "local",
}

const StyledContentItem = styled.div`
  padding: 0 5px;
  font-size: 0.9rem;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & > label {
    font-weight: bold;
    font-size: 0.8rem;
    display: block;
    padding-bottom: 5px;
  }
`;

export const DateTimeInLocal = ({ dateTime = "", format = DATE_TIME_FORMAT }: IDateTimeArgs) => {
  if (!dateTime) {
    return <>-</>;
  }

  return <span>{moment(dateTime).local().format(format)}</span>;
};

export const DateTimeInUTC = ({ dateTime = "", format = DATE_TIME_FORMAT }: IDateTimeArgs) => {
  if (!dateTime) {
    return <>-</>;
  }

  return <span>{moment.utc(dateTime).format(format)}</span>;
};

export const DateTime = ({ dateTime = "", format = DATE_TIME_FORMAT, transformTo }: IDateTimeArgs) => {
  if (!dateTime) {
    return <>-</>;
  }

  if (transformTo === DateTimeTransformationTypes.UTC) {
    return <DateTimeInUTC dateTime={dateTime} format={format} />;
  }

  if (transformTo === DateTimeTransformationTypes.LOCAL) {
    return <DateTimeInLocal dateTime={dateTime} format={format} />;
  }

  return <span>{moment(dateTime).format(format)}</span>;
};

export const HumanisedDuration = ({ duration }: HumanisedDurationArgs) => {
  return <span>{moment.duration(duration).humanize()}</span>;
};

export const ElapsedTime = ({ dateTime }: ElapsedTimeArgs) => {
  if (!dateTime) {
    return <>-</>;
  }
  return <span>{moment(dateTime).fromNow()}</span>;
};

export const FormattedDateWithLabel = ({ dateTime = "", format = DATE_TIME_FORMAT, label, transformTo }: IFormattedDateWithLabelArgs) => {
  return (
    <StyledContentItem>
      {label && (
        <label>
          {label} (<i>in {transformTo}</i>)
        </label>
      )}
      <div>
        <DateTime dateTime={dateTime} transformTo={transformTo} format={format} />
      </div>
    </StyledContentItem>
  );
};

export const FormattedElapsedTimeWithLabel = ({ dateTime = "", label }: IFormattedDateWithLabelArgs) => {
  return (
    <StyledContentItem>
      {label && <label>{label}</label>}
      <div>
        <ElapsedTime dateTime={dateTime} />
      </div>
    </StyledContentItem>
  );
};

export const DateTimePopupWithUTCAndLocalTime = ({ dateTime = "", format = DATE_TIME_FORMAT, label }: IDateTimePopupWithUTCAndLocalTimeArgs) => {
  const dateTypePreference = usePersistentStorage((state) => state.dateTypePreference);
  return (
    <Popup
      size="mini"
      wide="very"
      content={
        <>
          <FormattedDateWithLabel label={label} format={format} dateTime={dateTime} transformTo={DateTimeTransformationTypes.UTC} />
          <FormattedDateWithLabel label={label} format={format} dateTime={dateTime} transformTo={DateTimeTransformationTypes.LOCAL} />
        </>
      }
      trigger={
        <span>{dateTypePreference === DATE_TYPE_PREFERENCE.DATE ? <DateTime dateTime={dateTime} format={format} /> : <ElapsedTime dateTime={dateTime} />}</span>
      }
    />
  );
};
