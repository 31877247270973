//
// DISCLAIMER
//
// Copyright 2022 ArangoDB GmbH, Cologne, Germany
//

import styled from "@emotion/styled";
import React from "react";

interface IFlexProps {
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
  align?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  className?: string;
  style?: React.CSSProperties;
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  alignContent?: "flex-start" | "flex-end" | "center" | "stretch" | "space-between" | "space-around";
  alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  grow?: number | string;
  shrink?: number | string;
  basis?: number | string;
  flex?: number | string;
  children: React.ReactNode | undefined;
  margin?: number | string;
  cursor?: string;
  width?: number | string;
  maxWidth?: number | string;
  lineheight?: number | string;
  id?: string;
  position?: "relative" | "absolute" | "fixed" | "sticky";
  overflow?: "visible" | "hidden" | "scroll" | "auto";
  overflowX?: "visible" | "hidden" | "scroll" | "auto";
  overflowY?: "visible" | "hidden" | "scroll" | "auto";
  padding?: number | string;
}

const FlexView = React.forwardRef((props: IFlexProps, ref: React.Ref<HTMLDivElement> | null) => {
  return (
    <div className="flex-group" {...props} ref={ref}>
      {props.children}
    </div>
  );
});

const StyledFlex = styled(FlexView)`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.alignContent};
  flex-grow: ${(props) => props.grow};
  flex-shrink: ${(props) => props.shrink};
  flex-basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  line-height: ${(props) => props.lineheight};
  position: ${(props) => props.position};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

export { StyledFlex as FlexBox };
