//
// DISCLAIMER
//
// Copyright 2020-2022 ArangoDB GmbH, Cologne, Germany
//

import _ from "lodash";
import React, { Component, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, DropdownProps, Form, Message, Modal, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { LogLevelEntry as ApiLogLevelEntry, SetDeploymentLogLevelRequest as ApiSetDeploymentLogLevelRequest } from "../../api/lib";
import { Deployment_ServerStatus as ApiDeployment_ServerStatus } from "../../api/lib";
import { ContentActionButton, ErrorMessage, FormActionButtonCancel, FormActionButtonSave, Processing, TextLink } from "../../ui/lib";
import { AsyncResult } from "../../util/Types";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface IRole {
  id: string;
  title: string;
}

const roles = [
  { id: "all", title: "All" },
  { id: "agents", title: "Agents" },
  { id: "coordinators", title: "Coordinators" },
  { id: "dbservers", title: "DBServers" },
  { id: "singles", title: "Single" },
] as IRole[];

const levelKeys = [
  "agency",
  "agencycomm",
  "aql",
  "arangosearch",
  "audit-authentication",
  "audit-authorization",
  "audit-collection",
  "audit-database",
  "audit-document",
  "audit-hotbackup",
  "audit-view",
  "audit-service",
  "authentication",
  "authorization",
  "backup",
  "cache",
  "cluster",
  "collector",
  "communication",
  "clustercomm",
  "compactor",
  "config",
  "datafiles",
  "development",
  "dump",
  "engines",
  "flush",
  "general",
  "graphs",
  "heartbeat",
  "httpclient",
  "ldap",
  "maintenance",
  "memory",
  "mmap",
  "performance",
  "pregel",
  "queries",
  "replication",
  "requests",
  "restore",
  "rocksdb",
  "security",
  "ssl",
  "startup",
  "statistics",
  "supervision",
  "syscall",
  "threads",
  "trx",
  "ttl",
  "v8",
  "views",
];

const levelValues = ["FATAL", "ERROR", "WARNING", "INFO", "DEBUG", "TRACE"];

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

interface ISetLogLevelModalViewArgs {
  open: boolean;
  processing: boolean;
  onClose: () => void;

  servers?: ApiDeployment_ServerStatus[];
  selectedRoleId: string;
  onRoleFilterChanged: (id: string) => void;

  levels: ApiLogLevelEntry[];
  onLevelChanged: (key: string, value: string) => void;
  onRemoveLevel: (key: string) => void;

  onSetLevels: () => Promise<AsyncResult<void>>;
}

const SetLogLevelModalView = ({ ...args }: ISetLogLevelModalViewArgs) => {
  const [isSettingComplete, updateSettingStatus] = useState(false);
  const [error, updateError] = useState<string>("");

  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const servers = args.servers || [];
  const hasAgents = servers.some((x) => x.type == "Agent");
  const hasSingles = servers.some((x) => x.type == "Single");
  const hasCoordinators = servers.some((x) => x.type == "Coordinator");
  const hasDBServers = servers.some((x) => x.type == "Dbserver");

  const handleLogLevelChanged = async () => {
    const { error } = await args.onSetLevels();
    if (!error) {
      updateSettingStatus(true);
      updateError("");
    } else {
      updateError(error);
    }
  };

  const roleOptions = roles
    .filter((x, i) => {
      switch (x.id) {
        case "all":
          return true;
        case "agents":
          return hasAgents;
        case "coordinators":
          return hasCoordinators;
        case "dbservers":
          return hasDBServers;
        case "singles":
          return hasSingles;
      }
      return false;
    })
    .map((x, i) => {
      return {
        key: `role-${x.id || ""}`,
        text: x.title,
        value: x.id,
      };
    });

  const remainingLevelKeys = _.xor(
    levelKeys,
    args.levels.map((x) => x.key || "")
  );
  const addKeyOptions = remainingLevelKeys.map((x, i) => {
    return {
      key: `key-${x}`,
      text: x,
      value: x,
    };
  });

  const levelValueOptions = levelValues.map((x, i) => {
    return {
      key: `value-${x}`,
      text: x,
      value: x,
    };
  });

  return (
    <Modal open onClose={args.onClose}>
      <Modal.Header>Set log level</Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={error} />
        {!isSettingComplete && (
          <>
            <Message>
              Recent changes to the log levels can be viewed in the{" "}
              <TextLink href="#jobs">
                {" "}
                <b> Jobs tab </b>{" "}
              </TextLink>
            </Message>
            <Processing active={args.processing} message="Setting log levels..." />
            <Form onSubmit={args.onSetLevels}>
              <Form.Field>
                <label>Role:</label>
                <Dropdown
                  fluid
                  button
                  scrolling
                  value={args.selectedRoleId}
                  text={getOptionText(roleOptions, args.selectedRoleId) || "?"}
                  options={roleOptions}
                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => args.onRoleFilterChanged(data.value as string)}
                />
              </Form.Field>

              <Form.Field>
                <label>Log levels:</label>
                {_.isEmpty(args.levels) && <div>No components</div>}
                <Table striped compact>
                  <Table.Body>
                    {args.levels.map((level, idx) => (
                      <Table.Row key={`row${idx}`}>
                        <Table.Cell>{level.key}</Table.Cell>
                        <Table.Cell>
                          <Dropdown
                            fluid
                            value={level.value}
                            text={level.value}
                            options={levelValueOptions}
                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
                              args.onLevelChanged(level.key || "", data.value as string)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Button type="button" icon="trash" basic size="tiny" circular onClick={() => args.onRemoveLevel(level.key || "")} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Form.Field>
              <Form.Field>
                <label>Add component:</label>
                <Dropdown
                  button
                  fluid
                  scrolling
                  search
                  text="Select component"
                  options={addKeyOptions}
                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => args.onLevelChanged(data.value as string, "DEBUG")}
                />
              </Form.Field>
            </Form>
          </>
        )}
        {isSettingComplete && (
          <>
            Log level setting completed. You can visit the
            <TextLink href="#jobs">
              {" "}
              <b> Jobs tab </b>{" "}
            </TextLink>
            page to see the changes.
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {!isSettingComplete ? (
          <FormActionButtonSave primary onClick={handleLogLevelChanged} title="Set" icon="check" disabled={args.processing} />
        ) : (
          <FormActionButtonSave primary onClick={() => updateSettingStatus(false)} title="Set More" icon="check" disabled={args.processing} />
        )}
        <FormActionButtonCancel onClick={args.onClose} title={isSettingComplete ? "Close" : "Cancel"} />
      </Modal.Actions>
    </Modal>
  );
};

interface ISetLogLevelModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
  servers?: ApiDeployment_ServerStatus[];
}

interface ISetLogLevelModalState {
  open: boolean;
  processing: boolean;
  selectedRoleId: string;
  errorMessage?: string;
  levels: ApiLogLevelEntry[];
}

// Component to set deployment log level
class SetLogLevelModal extends Component<ISetLogLevelModalProps, ISetLogLevelModalState> {
  state: ISetLogLevelModalState = {
    open: false,
    processing: false,
    selectedRoleId: "all",
    errorMessage: undefined,
    levels: [],
  };

  onSetLevels = async (): Promise<AsyncResult<void>> => {
    this.setState({ processing: true, errorMessage: undefined });
    let error = "";
    try {
      const req: ApiSetDeploymentLogLevelRequest = {
        deployment_id: this.props.deploymentId,
        levels: this.state.levels,
      };
      const foundSingle = this.props.servers && this.props.servers.some((r) => r.type === "Single");
      const role = roles.find((r) => r.id === this.state.selectedRoleId);

      // If its a developer deployment with single server, always attach "singles" to request
      if (foundSingle) {
        req.role = "singles";
      } else if (!!role && role.id != "all") {
        req.role = role.id;
      }
      await apiClients.idashboardClient.SetDeploymentLogLevel(req);
      this.setState({ processing: false });
    } catch (err) {
      this.setState({ processing: false, errorMessage: err });
      error = err;
    }
    return { error };
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onRoleFilterChanged = (id: string) => {
    this.setState({ selectedRoleId: id });
  };

  onLevelChanged = (key: string, value: string) => {
    this.setState((old) => {
      const levels = _.filter(old.levels, (l) => l.key != key).concat({ key: key, value: value });
      return { levels: _.sortBy(levels, "key") };
    });
  };

  onRemoveLevel = (key: string) => {
    this.setState((old) => {
      const levels = _.filter(old.levels, (l) => l.key != key);
      return { levels: _.sortBy(levels, "key") };
    });
  };

  render() {
    return (
      <span>
        <ContentActionButton primary onClick={this.onOpen} content="Set log level" icon="settings" />
        {this.state.open && <SetLogLevelModalView {...this.props} {...this.state} {...this} />}
      </span>
    );
  }
}

export default withRefresh()(SetLogLevelModal);
